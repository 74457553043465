@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

:root {
  --white: #fff;
  --black: #rgba(0, 0, 0, 1);
  --blue: rgb(3, 3, 54);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Mono", monospace;
}

body {
  background-color: transparent;
}

.app {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    180deg,
    #b4b4e9 0%,
    rgba(255, 255, 255, 0) 39.06%
  );

  color: white;
}
