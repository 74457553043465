.content {

  .container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 120px;

    .interface {
      width: 400px;
      box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.15);
      border-radius: 8px;

      .columns {
        width: 100%;
        display: flex;

        .form {
          width: 400px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .input {
            width: 333px;
            margin-top: 15px;

            .title {
              padding-bottom: 5px;

              label {
                color: black;
                font-size: 14px;
              }
            }

            input {
              width: 100%;
              height: 38px;
              border-radius: 0.28571429rem;
              border: 1px solid rgba(192, 192, 192, 1);
              outline: none;
              padding: 10px;
            }
          }

          .input:nth-child(1) {
            margin-top: 5px;
          }

          .walletStatus {
            width: 330px;
            color: black;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;

            .ball {
              width: 14px;
              height: 14px;
              border-radius: 15px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}
