.header {
  width: 100%;
  height: 80px;
  background-color: #000000;
  font-family: "PT Mono", monospace;
  position: absolute;

  .sides {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      width: 300px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        padding-left: 15px;
        cursor: pointer;
        width: 60px;
        height: 40px;
      }
    }

    .right {
      width: 300px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: black;

      img {
        padding-left: 15px;
        cursor: pointer;
      }

      .connectButton {
        box-shadow:inset 0px -3px 7px 0px #29bbff;
        background:linear-gradient(to bottom, #2dabf9 5%, #0688fa 100%);
        background-color:#2dabf9;
        border-radius:3px;
        border:1px solid #0b0e07;
        display:inline-block;
        cursor:pointer;
        color:#ffffff;
        font-family:Arial;
        font-size:15px;
        padding:9px 23px;
        text-decoration:none;
        text-shadow:0px 1px 0px #263666;
      }
      .connectButton:hover {
        background:linear-gradient(to bottom, #0688fa 5%, #2dabf9 100%);
        background-color:#0688fa;
      }
      .connectButton:active {
        position:relative;
        top:1px;
      }
    }
  }

  .decoration {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 500px;
    }
  }
}
